import React from 'react'
import { Bling as Ads } from 'react-gpt'
import { Configure } from 'react-instantsearch-dom'
import { connectStateResults } from 'react-instantsearch-dom'
import { Fade } from 'react-reveal'
import Sticky from 'react-stickynode'
import classNames from 'classnames'

import { getTopStations } from '../../networking.js'
import { LeaderboardAd, LargeRectangleAd, MobileAd } from '../../ads.js'
import Page from '../templates/Page'
import SearchResults from '../components/SearchResults'
import GenreMenu from '../components/GenreMenu'
import { RowLayout } from '../../components/layout'

import './listen.css'

class Listen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      featuredStations: []
    }

    this.stationLogoSize = '120px'

    this.invalidateLayout = this.invalidateLayout.bind(this)
  }

  componentDidMount() {
    this.loadData()

    this.adsRefreshIntervalID = setInterval(function() {
      Ads.refresh()
      console.log('listen: refresh ads')
    }, 30000)

    if (typeof window !== 'undefined') {
      this.invalidateLayout()
      window.addEventListener('resize', this.invalidateLayout)
    }
  }

  componentWillUnmount() {
    clearInterval(this.adsRefreshIntervalID)
    window.removeEventListener('resize', this.invalidateLayout)
  }

  loadData() {
    getTopStations(stations => {
      this.setState({
        featuredStations: stations
      })
    })
  }

  invalidateLayout() {
    const previousStationLogoSize = this.stationLogoSize

    if (window.innerWidth > 768) {
      this.stationLogoSize = '120px'
    } else {
      this.stationLogoSize = '80px'
    }

    if (previousStationLogoSize !== this.stationLogoSize) {
      this.forceUpdate()
    }
  }

  render() {
    const isQueryActive = this.props.searchState && this.props.searchState.query

    const isMenuActive =
      this.props.searchState &&
      this.props.searchState.menu &&
      this.props.searchState.menu['genres.name']

    const isSearchActive = isQueryActive || isMenuActive

    const searchString = () => {
      if (isQueryActive) {
        return this.props.searchState.query
      } else if (isMenuActive) {
        return this.props.searchState.menu['genres.name']
      }
    }

    const content =
      isSearchActive && this.props.searchResults
        ? this.props.searchResults.hits
        : this.state.featuredStations

    return (
      <Page
        className={classNames('listen', {
          'from-search': this.props.location.search.length > 0
        })}
      >
        <RowLayout isResponsive>
          <div className="results">
            {typeof window !== 'undefined' && window.innerWidth > 1200 && (
              <LeaderboardAd />
            )}

            <h1 className="results-heading">
              {isSearchActive
                ? `Stations found for "${searchString()}"`
                : 'Top Stations'}
            </h1>

            <Configure hitsPerPage={1000} />

            <SearchResults
              results={content || []}
              resultProps={{
                session: this.props.playerSession,
                update: this.props.updatePlayerSession,
                logoSize: this.stationLogoSize
              }}
            />
          </div>

          <Fade left duration={0}>
            <div className={classNames('sidebar')}>
              <GenreMenu />

              {typeof window !== 'undefined' && window.innerWidth > 768 ? (
                <Sticky bottomBoundary=".listen">
                  <LargeRectangleAd />
                </Sticky>
              ) : (
                <MobileAd />
              )}
            </div>
          </Fade>
        </RowLayout>
      </Page>
    )
  }
}

export default connectStateResults(Listen)
