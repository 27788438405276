import React from 'react'
import { Link } from 'gatsby'
import { AutoSizer, WindowScroller, List } from 'react-virtualized'
import classNames from 'classnames'

import PlayableStationLogo from '../../components/PlayableStationLogo'
import { urlForStation } from '../../helpers'

export default function SearchResults({ results, resultProps }) {

  function renderResult({ index, key, style }) {

    return (
      <Result
        key={key}
        data={results[index]}
        session={resultProps.session}
        update={resultProps.update}
        logoSize={resultProps.logoSize}
        style={style}
      />
    )
  }

  return (

    <WindowScroller>
      {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <div ref={registerChild}>
              <List
                autoHeight
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                rowCount={results.length}
                rowHeight={Number(resultProps.logoSize.split('px')[0]) + 32}
                rowRenderer={renderResult}
                scrollTop={scrollTop}
                width={width}
                tabIndex={null}
                containerStyle={{ overflow: 'visible' }}
              />
            </div>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  )
}

function Result({ data, session, update, logoSize, style }) {

  var genres = []

  data['genres'].forEach((genre) => {
    if (genre['sub-genres']) {
      genres.push(...genre['sub-genres'])
    } else {
      genres.push(genre)
    }
  })

  return (

    <div className={classNames('result-item')} style={style}>

      <PlayableStationLogo
        stationID={data['mount-id']}
        stationName={data['station-name']}
        stationLogoURL={data['logo']}
        stationURL={urlForStation(data['mount-id'], data['slug'])}
        size={logoSize}
      />

      <div className='station-details'>
        <Link
          to={urlForStation(data['mount-id'], data['slug'])}
          className='station-link'
        >
        <p className='station-name'>{data['station-name']}</p>
        </Link>
        <ul className='station-genres'>
          {genres.map(genre => <li key={genre.id}>{genre.name}</li>)}
        </ul>
      </div>

    </div>
  )
}
