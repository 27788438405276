import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Footer from '../components/Footer'

import PageContent from '../old/pages/Listen'
import '../assets/styles.css'

export default ({ location }) => (
  <React.Fragment>
    <SEO
      title={'Listen to Free Internet Radio'}
      pathname={location.pathname}
      scripts={[{ src: '//biddr.brealtime.com/92516381-1202.js' }]}
    />
    <PageContent location={location} />
    <Footer />
  </React.Fragment>
)

export const query = graphql`
  query {
    allStation {
      edges {
        node {
          fields {
            url
          }
        }
      }
    }
  }
`
