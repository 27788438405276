// station URL creator
exports.urlForStation = (id, slug) => {
  if (id === undefined || id === null) {
    return ''
  } else if (slug === undefined || slug === null) {
    return '/station/' + id
  } else {
    // truncate the generated slug (until station names are limited length)
    return '/station/' + slug.slice(0, 30) + '-' + id
  }
}
