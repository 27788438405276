import React from 'react'
import { ClearRefinements } from 'react-instantsearch-dom'
import { connectMenu } from 'react-instantsearch-dom'
import classNames from 'classnames'

class GenreMenu extends React.Component {
  render() {
    return (
      <div
        className={classNames({
          'genre-panel': true
        })}
      >
        <h1
          className={classNames({
            'genre-heading': true
          })}
        >
          Genres
        </h1>

        <ClearRefinements
          translations={{
            reset: 'Clear Filter'
          }}
        />

        <Menu attribute={'genres.name'} limit={22} />
      </div>
    )
  }
}

const Menu = connectMenu(CustomMenu)

function CustomMenu(props) {
  return (
    <ul
      className={classNames({
        'genre-list': true
      })}
    >
      {allGenres.map((item, index) => (
        <li
          key={index}
          className={classNames({
            'genre-item': true,
            'genre-item--selected': props.currentRefinement === item.value
          })}
        >
          <button
            onClick={() => props.refine(item.value)}
            className={classNames({
              'genre-button': true
            })}
          >
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  )
}

const allGenres = [
  {
    label: 'Alternative',
    value: 'Alternative'
  },
  {
    label: 'Blues',
    value: 'Blues'
  },
  {
    label: 'Classical',
    value: 'Classical'
  },
  {
    label: 'Country',
    value: 'Country'
  },
  {
    label: 'Decades',
    value: 'Decades'
  },
  {
    label: 'Easy Listening',
    value: 'Easy Listening'
  },
  {
    label: 'Electronic/Dance',
    value: 'Electronic/Dance'
  },
  {
    label: 'Folk',
    value: 'Folk'
  },
  {
    label: 'Freeform',
    value: 'Freeform'
  },
  {
    label: 'Hip-Hop/Rap',
    value: 'Hip-Hop/Rap'
  },
  {
    label: 'Holiday',
    value: 'Holiday'
  },
  {
    label: 'Inspirational',
    value: 'Inspirational'
  },
  {
    label: 'International',
    value: 'International'
  },
  {
    label: 'Jazz',
    value: 'Jazz'
  },
  {
    label: 'Latin',
    value: 'Latin'
  },
  {
    label: 'Metal',
    value: 'Metal'
  },
  {
    label: 'New Age',
    value: 'New Age'
  },
  {
    label: 'Pop',
    value: 'Pop'
  },
  {
    label: 'Reggae',
    value: 'Reggae'
  },
  {
    label: 'Rock',
    value: 'Rock'
  },
  {
    label: 'Talk',
    value: 'Talk'
  },
  {
    label: 'Urban and R&B',
    value: 'Urban and R&B'
  }
]

export default GenreMenu
