import axios from 'axios'
import qs from 'qs'

const baseURL = 'https://api.live365.com'

export function getCountryCodeForUserLocation(completion) {
  const config = {
    url: baseURL + '/geocheck',
    method: 'get'
  }

  request(config, data => {
    completion(data.country)
  })
}

export function getGenres(completion) {
  const config = {
    url: baseURL + '/genres',
    method: 'get'
  }

  request(config, completion)
}

export function getTopStations(completion) {
  const config = {
    url: baseURL + '/search/all',
    method: 'get'
  }

  request(config, completion)
}

export function getStationsByName(name, completion) {
  const config = {
    url: baseURL + '/search/web/title',
    method: 'post',
    data: qs.stringify({ title: name })
  }

  request(config, completion)
}

export function getStationsByGenreID(id, completion) {
  const config = {
    url: baseURL + '/search/web/genre/' + id,
    method: 'get'
  }

  request(config, completion)
}

export function getStationDataByID(id, completion) {
  const config = {
    url: baseURL + '/station/' + id + cacheBuster(),
    method: 'get'
  }

  request(config, completion)
}

function request(config, completion) {
  axios
    .request(config)

    .then(response => {
      completion(response.data)
    })

    .catch(error => {
      console.log(error)
      completion(null)
    })
}

function cacheBuster() {
  var string = '?cache='
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < 10; i++)
    string += possible.charAt(Math.floor(Math.random() * possible.length))

  return string
}
